@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,600;1,100;1,200&family=Roboto:wght@300;400;500;700&display=swap');

html {
  height: 100%;
  background-color: white;
}

body {
  height: 100%;
}

#root{
  height: 100%;
}

/*loder css start from here*/
.wrapper-loder {
  background-color: #94baed;
  height: 100vh;
  width: 100% !important;
  position: fixed;
  top: 0;
  display: flex;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.spinner {
  width: 60px;
  height: 60px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #83cb7e;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*Estilos sidebar escritorio*/

#desktopSidebar{
  @apply bg-[#223c7d];
}

#desktopSidebar svg{
  @apply text-white;
}

#desktopSidebar button:hover {
  @apply hover:bg-sky-800 font-semibold
}

#desktopSidebar a:hover {
  @apply hover:bg-sky-800 font-semibold
}

/*Estilos sidebar móvil*/

#mobileSidebar{
  @apply text-white bg-[#223c7d];
}

#mobileSidebar svg{
  @apply text-white;
}

#mobileSidebar button:hover {
  @apply hover:bg-sky-800 font-semibold
}

#mobileSidebar a:hover {
  @apply hover:bg-sky-800 font-semibold
}

/*Estilos navbar movil*/

#navbarOrg span:first-child{
  @apply bg-submarine-500 border-submarine-800 ;
}

#navbarOrg{
  @apply hover:bg-submarine-50 ;
}

/*Estilos botones*/

.btnMovinglake{
  @apply bg-submarine-500 hover:bg-sky-800 transition text-white rounded-sm px-3 py-2 inline-flex items-center;
}

.modalBtnMovinglake{
  @apply inline-flex w-full justify-center rounded-md bg-submarine-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800 sm:col-start-2;
}


/*Estilos tab*/

.tabMovinglake{
  @apply border-submarine-500 text-submarine-600;
}

.iconTabMovinglake{
  @apply text-submarine-500;
}

.animateList{
  animation: bounceInLeft;
  animation-duration: 0.5s;
}

.sourcesList{
  animation: zoomInLeft;
  animation-duration: 0.5s;
}

.destinationsList{
  animation: fadeInTopLeft;
  animation-duration: 0.5s;
}

/*Estilos billing*/

.featuredBilling{
  @apply bg-[#223c7d] text-white;
}

.featuredBtn{
  @apply bg-submarine-600 text-white rounded-sm px-3 py-2 inline-flex items-center;
}